import { curry } from 'ramda';
import axios from 'axios';
import store from '@/store';
import Vue from 'vue';
import i18n from '@/i18n';
import FileSaver from 'file-saver';

const message = new Vue();

export const http = axios.create({
  baseURL: '/api',
  headers: {
    'X-Requested-With': 'XMLHttpRequest'
  }
});

function getAccessToken() {
  return store.state.oidcStore.access_token;
}

http.interceptors.request.use(
  request => {
    request.headers['Authorization'] = `Bearer ` + getAccessToken();
    return request;
  },
  error => {
    return Promise.reject(error);
  }
);

const sendRequest = curry((client, verb, url, payload = null) => {
  return client[verb](url, payload)
    .then(response => {
      return Promise.resolve(response);
    })
    .catch(error => {
      message.$message({
        message: i18n.t('errors.general'),
        type: 'error'
      });
      return Promise.reject(error);
    });
});

export const fileDownload = async (endpoint, filename) => {
  return await fetch(endpoint, {
    method: "GET",
    headers: {
      "Authorization": `Bearer ${getAccessToken()}`
    }
  }).then(async (res) => {
    if (!res.ok) {
      const json = await res.json();
      throw new Error(json.detail)
    } else {
      return res;
    }
  }).then(response => {
    if (response !== undefined) {
      return response.blob();
    }
  }).then(blob => {
    if (blob !== undefined) {
      FileSaver.saveAs(blob, filename);
    }
  }).catch(err => {
    console.error(err);
    throw error;
  })
}

const api = {
  get: sendRequest(http, 'get'),
  post: sendRequest(http, 'post'),
  patch: sendRequest(http, 'patch'),
  put: sendRequest(http, 'put'),
  delete: sendRequest(http, 'delete')
};

export default api;
