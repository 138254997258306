import api from '@/services/api';

export const getCourses = async () => {
  // const result = await api.get(`/courses?lang=${lang}`)
  const result = await api.get(`/courses`);
  return result.data;
};

export const getCourse = async id => {
  // const result = await api.get(`/courses${id}?lang=${lang}`)
  const result = await api.get(`/courses/${id}`);
  return result.data;
};

export const createCourse = async payload => {
  // const result = await api.post(`/courses?lang=${lang}`, payload)
  const result = await api.post(`/courses`, payload);
  return result.data;
};

export const updateCourse = async payload => {
  // const result = await api.patch(`/courses/${payload.id}?lang=${lang}`, payload)
  const result = await api.patch(`/courses/${payload.id}`, payload);
  return result.data;
};

export const deleteCourse = async id => {
  const result = await api.delete(`/courses/${id}`);
  return result.data;
};

export const getDeleteEffect = async id => {
  const result = await api.get(`/courses/${id}/delete`);
  return result.data;
};

export const publishCourse = async id => {
  const result = await api.patch(`/courses/${id}/publish`);
  return result.data;
};

export const copyCourse = async (id, payload) => {
  const result = await api.post(`/courses/${id}/copy`, payload);
  return result.data;
};

export const toggleCourseLock = async courseId => {
  const result = await api.patch(`/courses/${courseId}/toggleLock`);
  return result.data;
};

export const getCourseTypes = async () => {
  const result = await api.get(`/courses/courseTypes`);
  return result.data;
};
