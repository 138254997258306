import api from '@/services/api';

export const getModulesByCourse = async id => {
  const result = await api.get(`/courses/${id}/modules`);
  return result.data;
};

export const getModule = async id => {
  const result = await api.get(`/coursemodules/${id}`);
  return result.data;
};

// export const getSimpleModule = async (id) => {
//   const result = await api.get(`/coursemodules/${id}/simple`)
//   return result.data
// }

export const createCourseModule = async payload => {
  const result = await api.post(`/coursemodules`, payload);
  return result.data;
};

export const updateModule = async payload => {
  const result = await api.patch(`/coursemodules/${payload.id}`, payload);
  return result.data;
};

export const deleteModule = async id => {
  const result = await api.delete(`/coursemodules/${id}`);
  return result.data;
};
