<template>
  <ui-form
    @submit="handleSubmit"
    :submitDisabled="invalid > 0"
    @cancel="handleCancel"
  >
    <section class="mb-4">
      <el-row>
        <el-badge :hidden="invalidNo === 0" :value="invalidNo">

          <ui-button
            type="grey"
            class=""
            :class="{ 'chosen-language-button': selectedLanguage === 'no' }"
            @click.prevent="toggleLanguage('no')"
            >no
          </ui-button>
        </el-badge>
        <el-badge class="ml-2" :hidden="invalidEn === 0" :value="invalidEn">
          <ui-button
              type="grey"
            class=""
            :class="{ 'chosen-language-button': selectedLanguage === 'en' }"
            @click.prevent="toggleLanguage('en')"
            >en
          </ui-button>
        </el-badge>
      </el-row>
    </section>

    <section v-if="selectedLanguage === 'no'">
      <ui-form-field :label="$t('fields.name')" rules="required">
        <el-input type="text" v-model="transName"></el-input>
      </ui-form-field>
      <diV class="flex-column mb-8">
        <label for="sortOrder">{{ $t('fields.sort_order') }}</label>
        <el-input-number
          v-model="courseModule.sortOrder"
          :step="10"
          :min="0"
        ></el-input-number>
      </diV>
      <ui-form-field :label="$t('fields.description')" rules="required">
        <rich-text-field v-model="transDescr"></rich-text-field>
      </ui-form-field>
    </section>

    <section v-if="selectedLanguage === 'en'">
      <ui-form-field :label="$t('fields.name')" rules="required">
        <el-input type="text" v-model="transName"></el-input>
      </ui-form-field>
      <diV class="flex-column mb-8">
        <label for="sortOrder">{{ $t('fields.sort_order') }}</label>
        <el-input-number
          v-model="courseModule.sortOrder"
          :step="10"
          :min="0"
        ></el-input-number>
      </diV>
      <ui-form-field :label="$t('fields.description')" rules="required">
        <rich-text-field v-model="transDescr"></rich-text-field>
      </ui-form-field>
    </section>
  </ui-form>
</template>

<script>
import UiForm from '@/components/ui/UiForm';
import UiFormField from '@/components/ui/UiFormField';
import UiButton from '@/components/ui/UiButton';
import RichTextField from "@/components/form/RichTextField";

export default {
  props: {
    courseModule: {
      type: Object,
      required: true
    },
    invalid: {
      type: Number,
      default: 0
    },
    invalidNo: {
      type: Number,
      default: 0
    },
    invalidEn: {
      type: Number,
      default: 0
    }
  },

  components: {
    UiButton,
    UiForm,
    UiFormField,
    RichTextField
  },

  data: function() {
    return {
      selectedLanguage: 'no'
    };
  },

  computed: {
    transName: {
      get() {
        return this.courseModule.name.filter(
          t => t.lang === this.selectedLanguage
        )[0].value;
      },
      set(value) {
        this.courseModule.name.filter(
          t => t.lang === this.selectedLanguage
        )[0].value = value;
      }
    },
    transDescr: {
      get() {
        const trans = this.courseModule.description.filter(
          t => t.lang === this.selectedLanguage
        )[0].value;
        return trans === '<p></p>' ? '' : trans;
      },
      set(value) {
        this.courseModule.description.filter(
          t => t.lang === this.selectedLanguage
        )[0].value = value;
      }
    }
  },

  methods: {
    handleSubmit() {
      this.$emit('submit');
    },
    toggleLanguage(value) {
      this.selectedLanguage = value;
    },

    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
<style scoped>
.flex-column {
  display: flex;
  flex-direction: column;
}

.chosen-language-button {
  background-color: #c4c4c4 !important;
}
</style>
