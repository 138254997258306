<template>
  <div :class="type" class="button" :onclick="action" @click="handleClick">
    <p class="button-text">
      <slot></slot>
    </p>
  </div>
</template>

<script>
export default {
  name: "UiButton",
  props: ["type", "action"],
  methods: {
    handleClick(evt) {
      this.$emit("click", evt);
    },
  },
};
</script>

<style scoped>
.button {
  cursor: pointer;
  width: auto;
  border-radius: 0.25rem;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-weight: 700;
  padding: 0.2rem 0.6rem;
}
.red {
  background-color: #e20011;
  color: white;
}

.blue {
  background-color: #1f78fe;
  color: white;
}

.green {
  background-color: #00d895;
  color: black;
}
.grey {
  background-color: #f2f2f2;
  color: black;
}

.white {
  background-color: white;
  border: solid black 1px;
}

.disabled {
  background-color: #f2f2f2;
  color: gray;
}

.button-text {
  margin: 0.4em 0.8em 0.4em 0.8em;
  font-size: 0.8em;
}
</style>
