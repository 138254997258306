<template>
  <div>
    <editor-menu-bar
      :editor="editor"
      v-slot="{ commands, isActive }"
      v-if="showEditor"
    >
      <div class="editor-menu">
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.bold() }"
          @click.prevent="commands.bold"
        >
          <span class="font-bold">B</span>
        </button>
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.italic() }"
          @click.prevent="commands.italic"
        >
          <span class="italic">I</span>
        </button>
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.heading({ level: 1 }) }"
          @click.prevent="commands.heading({ level: 1 })"
        >
          <span class="">H1</span>
        </button>
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.heading({ level: 2 }) }"
          @click.prevent="commands.heading({ level: 2 })"
        >
          <span class="">H2</span>
        </button>
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.heading({ level: 3 }) }"
          @click.prevent="commands.heading({ level: 3 })"
        >
          <span class="">H3</span>
        </button>
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.underline() }"
          @click.prevent="commands.underline"
        >
          <UnderlineIcon class="icon" />
        </button>
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.ordered_list() }"
          @click.prevent="commands.ordered_list"
        >
          <OlIcon class="icon" />
        </button>
        <button
          class="editor-button"
          :class="{ 'editor-button-active': isActive.bullet_list() }"
          @click.prevent="commands.bullet_list"
        >
          <UlIcon class="icon" />
        </button>
      </div>
    </editor-menu-bar>

    <editor-content
      class="editor-content"
      :class="{ 'editor-content-active': isFocused }"
      :editor="editor"
      v-if="hideBorder === false"
    ></editor-content>
    <editor-content
      style="border: 0; padding: 0;"
      class="editor-content"
      :class="{ 'editor-content-active': isFocused }"
      :editor="editor"
      v-if="hideBorder === true"
    ></editor-content>
  </div>
</template>

<script>
import UnderlineIcon from '@/assets/images/underline.svg';
import OlIcon from '@/assets/images/ol.svg';
import UlIcon from '@/assets/images/ul.svg';
import { Editor, EditorContent, EditorMenuBar } from 'tiptap';
import {
  Bold,
  Italic,
  Link,
  Heading,
  Underline,
  OrderedList,
  BulletList,
  ListItem
} from 'tiptap-extensions';

export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    showEditor: {
      type: Boolean,
      default: true
    },
    hideBorder: {
      type: Boolean,
      default: false,
    }
  },

  data() {
    return {
      localValue: this.value,
      editor: null,
      editorChange: false,
      isFocused: false
    };
  },

  components: {
    EditorContent,
    EditorMenuBar,
    UnderlineIcon,
    OlIcon,
    UlIcon
  },

  methods: {
    handleInput(value) {
      this.$emit('input', value);
    }
  },

  mounted() {
    const editable = this._props.showEditor;
    this.editor = new Editor({
      editable: editable,
      content: this.localValue,
      extensions: [
        new Bold(),
        new Italic(),
        new Link(),
        new Heading({ levels: [1, 2, 3] }),
        new Underline(),
        new BulletList(),
        new OrderedList(),
        new ListItem()
      ],
      onFocus: () => {
        this.isFocused = true;
      },
      onBlur: () => {
        this.isFocused = false;
      },
      onUpdate: ({ getHTML }) => {
        this.editorChange = true;
        this.$emit('input', getHTML());
      }
    });
  },

  beforeDestroy() {
    if (this.editor) this.editor.destroy();
  },

  watch: {
    value(val) {
      if (this.editor && !this.editorChange) {
        this.editor.setContent(val, true);
      }
      this.editorChange = false;
    }
  }
};
</script>
<style>
.editor-content .ProseMirror ol {
  list-style-type: decimal;
}
.editor-content .ProseMirror ul {
  list-style: disc;
}
.icon {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  width: 0.8rem;
  height: 0.8rem;
  margin: 0 0.3rem;
  top: 0.12rem;
}
.icon svg {
  display: inline-block;
  vertical-align: top;
  width: 100%;
  height: 100%;
}
.editor-content .ProseMirror p {
  margin-bottom: 0px;
}
.editor-content .ProseMirror ol {
  margin-left: 15px;
}
.editor-content .ProseMirror ul {
  margin-left: 15px;
}

.editor-content {
  border: 1px solid #DCDFE6;
  border-radius: 4px;
}
</style>
