<template>
  <validation-observer
    ref="observer"
    tag="form"
    v-slot="{ invalid }"
    @submit.prevent="handleSubmit"
    autocomplete="off"
  >
    <slot></slot>

    <div class="flex">
      <ui-link
        type="primary"
        @click="handleSubmit"
        :disabled="submitDisabled"
        >{{ submitText }}</ui-link
      >
      <ui-link
        v-if="!hideCancel"
        class="ml-4"
        type="text"
        @click="handleCancel"
        >{{ cancelText }}</ui-link
      >

      <slot name="buttons"></slot>
    </div>
  </validation-observer>
</template>

<script>
import UiLink from '@/components/ui/UiLink';

export default {
  props: {
    submitText: {
      type: String,
      default: function() {
        return this.$t('actions.save');
      }
    },
    submitDisabled: {
      type: Boolean,
      default: false
    },
    cancelText: {
      type: String,
      default: function() {
        return this.$t('actions.cancel');
      }
    },
    hideCancel: {
      type: Boolean,
      default: false
    }
  },

  components: {
    UiLink
  },

  methods: {
    async handleSubmit() {
      const isValid = await this.$refs.observer.validate();
      if (isValid) {
        this.$emit('submit');
      }
    },

    handleCancel() {
      this.$emit('cancel');
    }
  }
};
</script>
